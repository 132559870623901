<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-model="showModal"
    :click-to-close="false"
  >
    <!-- layer : 이미지 등록 -->
    <section id="modalResultImg" class="layer_dialog">
      <h2 class="mb20">이미지 등록</h2>
      <!-- file upload -->
      <div class="upload">
        <span class="material-icons md-light ico_file">attach_file</span>
        <input
          type="text"
          id="addFile"
          :class="input_image_class"
          readonly="readonly"
          v-model="this.image_path"
          placeholder="5M 이하 / gif, png, jpg(jpeg)"
        />
        <span class="input_wrap" v-if="this.image_path === ''">
          <input
            type="button"
            class="btn btn_small btn_light pointer"
            value="업로드"
            title="업로드"
            @click="fileClick($event)"
          /><input
            type="file"
            class="input_hidden"
            @change="changeImage($event)"
          />
        </span>

        <!-- 업로드 후 -->
        <span v-else>
          <button
            type="button"
            @click="showImage()"
            class="btn btn_small btn_secondary mr10"
          >
            <span>이미지 보기</span>
          </button>
          <button type="button" @click="clearImage()">
            <span class="material-icons md-light">cancel</span>
          </button>
        </span>
      </div>
      <!-- //file upload -->

      <p class="mt20">
        <select
          name=""
          style="width: 160px"
          class="mr10"
          v-model="link_use"
          @change="changeLinkStatus()"
        >
          <option value="enabled">링크연결 사용함</option>
          <option value="disabled">링크연결 사용안함</option>
        </select>
        <input
          v-model="link"
          type="text"
          :class="link_class"
          autocomplete="off"
          placeholder="URL주소를 입력해주세요"
          :disabled="link_disabled"
          :spellcheck="false"
        />
        <font
          v-if="link_use == 'enabled'"
          style="display: block; margin-top: 10px"
          >링크 주소 입력에 http:// 또는 https:// 없는 경우 입력해야 저장이
          가능합니다.</font
        >
      </p>

      <div class="btns">
        <ul>
          <li>
            <button
              type="button"
              class="btn btn_large btn_secondary"
              @click="close"
            >
              <span>닫기</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              class="btn btn_large btn_primary"
              @click="setResult"
            >
              <span>저장</span>
            </button>
          </li>
        </ul>
      </div>
    </section>
    <!-- //layer : 이미지 등록 -->
  </vue-final-modal>
</template>

<script>
// import { mapState } from 'vuex';
import common from '@/components/mixin/index.js';

import ModalImagePreview from '@/components/qna/modal/ModalImagePreview.vue';

export default {
  mixins: [common],
  inheritAttrs: false,
  props: ['item', 'type', 'result_desktop_items', 'result_mobile_items'],
  data: () => ({
    showModal: true,
    image: '',
    image_path: '',
    desktop_items: [],
    mobile_items: [],
    link_disabled: true,
    link_use: 'disabled',
    link_class: 'urlText link_background_grey',
    link: '',
    input_image_class: 'input_read',
  }),
  created: async function () {
    this.init();
  },
  computed: {},
  mounted() {},
  methods: {
    init: function () {
      this.input_image_class = 'input_read update_input_read';
      this.desktop_items = this.result_desktop_items;
      this.mobile_items = this.result_mobile_items;
      if (this.item !== null) {
        this.image = this.item.image;
        this.image_path = this.item.image_path;

        if (this.type === 'desktop') {
          if (
            this.desktop_items[this.item.index].link_use &&
            this.desktop_items[this.item.index].link_use == 'enabled'
          ) {
            this.link_use = 'enabled';
            this.link = this.desktop_items[this.item.index].link;
            this.link_class = 'urlText link_background_white';
            this.link_disabled = false;
          }
        } else {
          if (
            this.mobile_items[this.item.index].link_use &&
            this.mobile_items[this.item.index].link_use == 'enabled'
          ) {
            this.link_use = 'enabled';
            this.link = this.mobile_items[this.item.index].link;
            this.link_class = 'urlText link_background_white';
            this.link_disabled = false;
          }
        }
      }
    },
    setResult: function () {
      if (this.image === '') {
        alert('이미지를 등록해주세요');
        return false;
      }

      if (this.link_use == 'enabled') {
        if (!this.link || this.link == '') {
          alert('URL주소를 입력해주세요');
          return false;
        }

        let isUrl = this.isUrlPattern(this.link);
        if (!isUrl) {
          alert('입력한 URL을 확인해주세요');
          return false;
        }
      }

      const params = {
        type: 'image',
        text: '이미지',
        image: this.image,
        image_path: this.image_path,
        link: this.link,
        link_use: this.link_use,
      };

      if (this.item !== null) {
        if (this.type === 'desktop') {
          this.desktop_items.splice(this.item.index, 1, params);
        } else {
          this.mobile_items.splice(this.item.index, 1, params);
        }
      } else {
        if (this.type === 'desktop') {
          this.desktop_items.push(params);
        } else {
          this.mobile_items.push(params);
        }
      }

      this.$emit('updateList', 'desktop', this.desktop_items);
      this.$emit('updateList', 'mobile', this.mobile_items);

      this.showModal = false;
    },
    changeImage: async function (event) {
      this.image_path = event.target.value;
      const result = await this.uploadImage(event.target.files[0]);
      if (!result) {
        this.image_path = '';
        this.input_image_class = 'input_read';
      } else {
        this.input_image_class = 'input_read update_input_read';
      }
      this.image = result;
    },
    clearImage() {
      this.image = '';
      this.image_path = '';
      this.input_image_class = 'input_read';
    },
    showImage() {
      const preview_image = this.image;

      this.$vfm.show({
        component: ModalImagePreview,
        bind: {
          image: preview_image,
        },
      });
    },
    changeLinkStatus: function () {
      if (this.link_use == 'enabled') {
        this.link_disabled = false;
        this.link_class = 'urlText link_background_white';
      } else {
        this.link_disabled = true;
        this.link_class = 'urlText link_background_grey';
        this.link = '';
      }
    },
    isUrlPattern: function (url) {
      if (url.indexOf('http://') < 0 && url.indexOf('https://') < 0) {
        return false;
      }
      return true;
    },
    fileClick: function (e) {
      $(e.target).next('input').click();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.urlText {
  width: 300px !important;
}
.upload {
  width: 100%;
}
.update_input_read {
  width: 340px;
}
.link_background_white {
  background: white;
}
.link_background_grey {
  background: #ccc;
}
.input_hidden {
  width: 63px;
  z-index: -1;
}
</style>
